@font-face {
  font-family: 'WorkSans';
  font-style: normal;
  font-weight: normal;
  src: local('WorkSans'),
    url('./fonts/WorkSans/WorkSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BerlingskeSerif';
  font-style: normal;
  font-weight: normal;
  src: local('BerlingskeSerif'),
    url('./fonts/BerlingskeSerif/BerlingskeSerif-Regular.ttf')
      format('truetype');
}
@font-face {
  font-family: 'BerlingskeSerifCn';
  font-style: italic;
  font-weight: normal;
  src: local('BerlingskeSerifCn'),
    url('./fonts/BerlingskeSerif/BerlingskeSerifCn-Italic.ttf')
      format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
